/*
 | =========================================================
 | This File is for member hub vaults state getters handling
 | =========================================================
 */
import dayjs from 'dayjs'
import memberhubConstants from '~/constants/memberhub.constants'
import { strToCapitalize } from '~/utils/helpers'

/*
 | =====================================
 | Premium amount rendering per vertical
 | @param product
 | @returns {null|*|string}
 | =====================================
 */
const premiumAmountPerVertical = product => {
  switch (product.vertical) {
    case memberhubConstants.VERTICAL_ID.HEALTH_INSURANCE:
      return product.payload?.premium_amount ? prependCurrency(product.payload?.premium_amount) : (product.premium_amount ? prependCurrency(product.premium_amount) : '')
    case memberhubConstants.VERTICAL_ID.LIFE_INSURANCE:
      return product.is_purchase_history ? prependCurrency(product.payload?.premium) : prependCurrency(product.payload?.premium_amount)
    case memberhubConstants.VERTICAL_ID.ENERGY:
      return product.payload?.monthly_bill
    case memberhubConstants.VERTICAL_ID.GENERIC:
      if (product.payload?.generic_product_type === memberhubConstants.GENERIC_PRODUCT_TYPE_FLAG.BROADBAND) {
        return ''
      }
      if (product.payload?.generic_product_type === memberhubConstants.GENERIC_PRODUCT_TYPE_FLAG.SIM) {
        return ''
      }
      if (product.is_purchase_history && product.payload.generic_product_type) {
        return product.payload?.price ? prependCurrency(product.payload?.price) : ''
      } return product.payload?.payment_amount ? prependCurrency(product.payload?.payment_amount) : ''
    case memberhubConstants.VERTICAL_ID.HOME_LOANS:
      return product.payload?.last_payment_amount ? prependCurrency(product.payload?.last_payment_amount) : ''
    default:
      return prependCurrency(product.payload?.premium_amount)
  }
}

/*
   | =========================
   | Product name per vertical
   | @param product
   | @returns {null|*|string}
   | =========================
   */
const productNamePerVertical = product => {
  switch (product.vertical) {
    case memberhubConstants.VERTICAL_ID.HEALTH_INSURANCE:
      return product.payload?.policy_details?.derived_name || product.payload?.policy_details?.name
    case memberhubConstants.VERTICAL_ID.LIFE_INSURANCE:
      if (product.is_purchase_history) {
        return product.payload?.quote_name || (product.payload?.proceeding_insurer ? `${product.payload?.proceeding_insurer} Protection` : 'Your Life Insurance Policy')
      }
      return product.payload?.provider?.fund_name
    case memberhubConstants.VERTICAL_ID.HOME_LOANS:
      return product.payload?.lender?.fund_name
    case memberhubConstants.VERTICAL_ID.ENERGY:
      return product.payload?.product_type
    case memberhubConstants.VERTICAL_ID.GENERIC:
      return product.payload?.provider || (product.payload?.category === 'Other' ? product.payload?.other_category : product.payload?.category)
    default:
      return product.payload?.policy_details?.name
  }
}

/*
    | =========================
    | Payment frequency per vertical
    | @param product
    | @returns {null|*|string}
    | =========================
    */
const paymentFrequencyPerVertical = product => {
  switch (product.vertical) {
    case memberhubConstants.VERTICAL_ID.HOME_LOANS:
      return `${paymentFrequencyText(product.payload?.payment_frequency)}**`
    case memberhubConstants.VERTICAL_ID.ENERGY:
      return `${paymentFrequencyText(product.payload?.payment_frequency)}*`
    case memberhubConstants.VERTICAL_ID.GENERIC:
      if (product.is_purchase_history && product.payload.generic_product_type) {
        return 'monthly'
      }
      return paymentFrequencyText(product.payload?.payment_frequency || product.payment_frequency)
    default:
      return paymentFrequencyText(product.payload?.payment_frequency || product.payment_frequency)
  }
}

/*
    | =========================
    | Payment frequency text
    | @param paymentFrequency
    | @returns {null|*|string}
    | =========================
    */
const paymentFrequencyText = paymentFrequency => {
  return getKey(paymentFrequency, {
    Weekly:
       'weekly',
    Fortnightly:
       'fortnightly',
    Monthly:
       'monthly',
    Quarterly:
       'quarterly',
    Annual:
       'yearly',
    Annually:
       'yearly',
    Yearly:
       'yearly',
    'Bi-Monthly':
       'bi-monthly',
    'Half Yearly':
       'half-yearly',
    'Half-yearly':
       'half-yearly',
    default:
       'monthly'
  })
}

/*
   | =============================
   | Product rate string rendering
   | @param product
   | @returns string
   | =============================
   */
const productInterestRateString = product => {
  // eslint-disable-next-line default-case
  switch (product.payload?.loan_type) {
    case 'Fixed':
      return product.payload?.fixed_rate ? `${appendPercentage(product.payload?.fixed_rate)} fixed${(product.payload?.fixed_rate_term && product.payload?.fixed_rate_term !== 'Other' ? ` for ${product.payload?.fixed_rate_term}` : '')}` : ''
    case 'Variable':
      return product.payload?.variable_rate ? `${appendPercentage(product.payload?.variable_rate)} variable` : ''
    case 'Split (Combined)':
      return product.payload?.fixed_rate || product.payload?.variable_rate ? `${appendPercentage(product.payload?.fixed_rate)} fixed${(product.payload?.fixed_rate_term && product.payload?.fixed_rate_term !== 'Other' ? ` for ${product.payload?.fixed_rate_term}` : '')}, ${appendPercentage(product.payload?.variable_rate)} variable` : ''
  }
}

/*
     | ===============================
     | Home loan amount text rendering
     | @param product
     | @returns {`${string}`}
     | ===============================
     */
const homeLoanAmount = product => {
  const amountSplitUps = []
  const fixedAmount = product.payload?.fixed_loan_amount ? `${prependCurrency(product.payload?.fixed_loan_amount)} fixed` : null
  const variableAmount = product.payload?.variable_loan_amount ? `${prependCurrency(product.payload?.variable_loan_amount)} variable` : null
  if (fixedAmount) {
    amountSplitUps.push(fixedAmount)
  }
  if (variableAmount) {
    amountSplitUps.push(variableAmount)
  }
  switch (product.payload?.loan_type) {
    case 'Split (Combined)':
      return `${prependCurrency(product.payload?.loan_amount)} ${(product.payload?.loan_term !== 'Other' ? `over ${(product.payload?.loan_term)}` : '')} ${(amountSplitUps.length ? `(${amountSplitUps.join(' + ')})` : '')}`
    default:
      return `${prependCurrency(product.payload?.loan_amount)} ${(product.payload?.loan_term !== 'Other' ? `over ${(product.payload?.loan_term)}` : '')}`
  }
}

/*
 * Get LIC application status from object
 * @param status
 * @returns {*}
 */
const licStatus = status => {
  return getKey(status, {
    Submitted: { text: 'SUBMITTED', color: 'blueb' },
    Assessed: { text: 'ASSESSED', color: 'yellowb' },
    Active: { text: 'ACTIVE', color: 'successbd' },
    Declined: { text: 'DECLINED', color: 'errorbd' },
    'Refer to Broker': { text: 'REFER TO BROKER', color: 'purplebd' },
    'Claim Made': { text: 'CLAIM MADE', color: 'orangebd' },
    Cancelled: { text: 'CANCELLED', color: 'bordered' },
    Closed: { text: 'CLOSED', color: 'bordered' }
  })
}

const getters = {
  /*
     * Accessor for user vault formData
     * @param state
     * @returns {*|{}}
     */
  getVaultFormData: state => {
    return state.vaultFormData || {}
  },
  /*
     * Accessor for vaults products formatted
     * @param state
     * @param getters
     * @returns {*}
     */
  getVaultProductsFormatted (state) {
    /*
          | =================================================
          | @param product
          | @returns {[{description: string, title: string}]}
          | =================================================
          */
    const productDetailsPerVertical = product => {
      const extraCoverExists = product?.payload?.policy_details && product?.payload?.policy_details.product_type === 'Hospital' && product?.payload?.cover_type === 'Combined'
      switch (product.vertical) {
        case memberhubConstants.VERTICAL_ID.HEALTH_INSURANCE:
          if (product.is_purchase_history) {
            return [
              {
                title: 'Policy name',
                description: `${product.payload?.policy_details?.derived_name || product.payload?.policy_details?.name || ''}${(extraCoverExists ? ` with ${product.payload?.extra_details?.derived_name || product.payload?.extra_details?.name}` : '')}`
              },
              {
                title: 'Coverage',
                description: product.payload?.coverage === 'Single Parent' ? 'Single Parent Family' : (product.payload?.coverage || '')
              },
              {
                title: 'Excess',
                description: product.payload?.policy_details?.excess ? '$' + product.payload?.policy_details?.excess : ''
              },
              {
                title: 'Membership #',
                description: product.payload?.policy_details?.new_membership_number || ''
              },
              {
                title: 'Application Date',
                description: product.payload?.purchase_date || ''
              },
              {
                title: 'Compare Club reference #',
                description: product?.opportunity_id || ''
              }
            ]
          }

          return [
            {
              title: 'Policy name',
              description: `${product.payload?.policy_details?.derived_name || product.payload?.policy_details?.name || ''}${(extraCoverExists ? ` with ${product.payload?.extra_details?.derived_name || product.payload?.extra_details?.name}` : '')}`
            },
            {
              title: 'Reference #',
              description: product.payload?.reference || ''
            },
            {
              title: 'Next payment',
              description: product.payload?.actual_next_payment_date || ''
            },
            {
              title: 'Coverage',
              description: product.payload?.coverage === 'Single Parent' ? 'Single Parent Family' : (product.payload?.coverage || '')
            },
            {
              title: 'Comments',
              description: product.payload?.comments || ''
            }
          ]
        case memberhubConstants.VERTICAL_ID.ENERGY:
          if (product.is_purchase_history) {
            const details = [
              {
                title: 'Retailer',
                description: product.payload?.retailer || ''
              },
              {
                title: 'Product Type',
                description: strToCapitalize(getKey(product.payload?.energy_product_type, memberhubConstants.ENERGY_PRODUCT_TYPE)?.toLowerCase()) || ''
              },
              {
                title: 'Plan',
                description: product.payload?.plan || ''
              },
              {
                title: 'Application Date',
                description: product.payload?.application_date ? dayjs(product.payload?.application_date, 'DD/MM/YYYY').format('DD MMM YYYY') : ''
              },
              {
                title: 'Address',
                description: product.payload?.address?.addressline || product.payload?.address || ''
              }
            ]
            if (product.payload?.energy_product_type === memberhubConstants.ENERGY_PRODUCT_TYPE_FLAG.ELECTRICITY) {
              details.push(...[
                {
                  title: 'NMI Number',
                  description: product.payload?.nmi_number || ''
                }
              ])
            }
            if (product.payload?.energy_product_type === memberhubConstants.ENERGY_PRODUCT_TYPE_FLAG.GAS) {
              details.push(...[
                {
                  title: 'MIRN Number',
                  description: product.payload?.mirn_number || ''
                }
              ])
            }
            details.push({
              title: 'Compare Club Reference #',
              description: product.payload?.live_submit_id || ''
            })
            return details
          }
          return [
            {
              title: 'Product',
              description: `${product.payload?.product_type} with ${product.payload?.retailer?.fund_name}`
            },
            {
              title: 'Next payment',
              description: product.payload?.actual_next_payment_date || ''
            },
            {
              title: 'Address',
              description: product.payload?.address?.addressline || product.payload?.address || ''
            },
            {
              title: 'Typical usage',
              description: product.payload?.energy_usage || ''
            },
            {
              title: 'Comments',
              description: product.payload?.comments || ''
            }
          ]
        case memberhubConstants.VERTICAL_ID.HOME_LOANS:
          // eslint-disable-next-line no-case-declarations
          const loanTerm = product.payload?.loan_term !== 'Other' ? product.payload?.loan_term?.replace(' year', '') : null
          // eslint-disable-next-line no-case-declarations
          const duration = loanTerm ? renderDuration(dayjs(), dayjs(product.payload?.loan_start_date, 'DD/MM/YYYY').add(loanTerm, 'year')) : null
          // eslint-disable-next-line no-case-declarations
          const remainingTerm = duration ? (duration.years ? `${duration.years} years` : '') + (duration.months ? ` ${duration.months} months` : '') : null
          return [
            {
              title: 'Lender',
              description: product.payload?.lender?.fund_name || ''
            },
            {
              title: 'Balance**',
              description: product.payload?.current_balance ? `${prependCurrency(product.payload?.current_balance)} ${(remainingTerm ? `over the next ${remainingTerm}` : '')}` : ''
            },
            {
              title: 'Loan type',
              description: product.payload?.loan_type || ''
            },
            {
              title: `${product.payload?.loan_type === 'Split (Combined)' ? 'Rates' : 'Rate'}`,
              description: productInterestRateString(product)
            },
            {
              title: 'Loan amount',
              description: homeLoanAmount(product)
            },
            {
              title: 'Started on',
              description: product.payload?.loan_start_date || ''
            },
            {
              title: 'Payment type',
              description: product.payload?.payment_type || ''
            },
            {
              title: 'Payment frequency',
              description: product.payload?.payment_frequency || ''
            },
            {
              title: 'Property type',
              description: product.payload?.property_type || ''
            },
            {
              title: 'Comments',
              description: product.payload?.comments || ''
            }
          ]
        case memberhubConstants.VERTICAL_ID.LIFE_INSURANCE:
          if (product.is_purchase_history) {
            const details = [
              {
                title: 'Provider',
                description: product.payload?.proceeding_insurer || ''
              },
              {
                title: 'Policy coverage',
                grouped: product.payload?.in_force_date ? dayjs(product.payload?.in_force_date, 'YYYY-MM-DD').isAfter(dayjs()) : true,
                policyCoverageTooltip: product.payload?.in_force_date ? !dayjs(product.payload?.in_force_date, 'YYYY-MM-DD').isAfter(dayjs()) : false,
                items: !product.payload?.in_force_date || dayjs(product.payload?.in_force_date, 'YYYY-MM-DD').isAfter(dayjs())
                  ? [
                    {
                      label: 'Life cover',
                      value: product.payload?.life_cover_quoted && parseFloat(product.payload?.life_cover_quoted) > 0 ? prependCurrency(formatAmount(product.payload?.life_cover_quoted)) || '' : ''
                    }, {
                      label: 'TPD cover',
                      value: product.payload?.tpd_quoted && parseFloat(product.payload?.tpd_quoted) > 0 ? prependCurrency(formatAmount(product.payload?.tpd_quoted)) || '' : ''
                    }, {
                      label: 'Trauma cover',
                      value: product.payload?.trauma_quoted && parseFloat(product.payload?.trauma_quoted) > 0 ? prependCurrency(formatAmount(product.payload?.trauma_quoted)) || '' : ''
                    }, {
                      label: 'Income protection',
                      value: product.payload?.income_protection && parseFloat(product.payload?.income_protection) > 0 ? (prependCurrency(formatAmount(product.payload?.income_protection)) || '') : ''
                    }
                  ]
                  : []
              }
            ]
            if (dayjs().diff(dayjs(product.payload?.in_force_date, 'YYYY-MM-DD'), 'day') >= 0 || !['Submitted', 'Assessed'].includes(product.payload?.status)) {
              details.push(...[
                {
                  title: 'Payment method',
                  description: product.payload?.payment_method || ''
                },
                {
                  title: 'Start date',
                  description: product.payload?.in_force_date ? dayjs(product.payload?.in_force_date, 'YYYY-MM-DD').format('DD/MM/YYYY') || '' : ''
                },
                {
                  title: 'Policy #',
                  description: product.payload?.policy_number || ''
                }
              ])
            } else {
              details.push(...[
                {
                  title: 'Price',
                  description: prependCurrency(product.payload?.premium) || ''
                },
                {
                  title: 'Payment frequency',
                  description: product.payload?.payment_frequency || ''
                },
                (['Submitted', 'Assessed'].includes(product.payload?.status)
                  ? {
                    title: 'Payment method',
                    description: product.payload?.payment_method || ''
                  }
                  : {}),
                {
                  title: 'Expected start date',
                  description: product.payload?.expected_inforce_date ? dayjs(product.payload?.expected_inforce_date).format('DD/MM/YYYY') || '' : ''
                },
                {
                  title: 'Application date',
                  description: product.payload?.app_submit_date ? dayjs(product.payload?.app_submit_date).format('DD/MM/YYYY') || '' : ''
                }
              ])
            }
            details.push(...[
              {
                title: 'Compare Club Reference #',
                description: product.payload?.live_submit_id || ''
              }
            ])
            return details
          }
          return [
            {
              title: 'Product',
              description: product.payload?.provider?.fund_name || ''
            },
            {
              title: 'Reference #',
              description: product.payload?.reference || ''
            },
            {
              title: 'Next payment',
              description: product.payload?.actual_next_payment_date || ''
            },
            {
              title: 'Payment Method',
              description: product.payload?.payment_method || ''
            },
            {
              title: 'Sum insured',
              grouped: true,
              items: [
                {
                  label: 'Life cover',
                  value: product.payload?.life_cover ? prependCurrency(product.payload?.life_cover) : null
                }, {
                  label: 'TPD cover',
                  value: product.payload?.tpd_cover ? prependCurrency(product.payload?.tpd_cover) : null
                }, {
                  label: 'Trauma cover',
                  value: product.payload?.trauma_cover ? prependCurrency(product.payload?.trauma_cover) : null
                }, {
                  label: 'Income protection',
                  value: product.payload?.income_protection ? prependCurrency(product.payload?.income_protection) : null
                }
              ]
            },
            {
              title: 'Comments',
              description: product.payload?.comments || ''
            }
          ]
        case memberhubConstants.VERTICAL_ID.GENERIC:
          if (product.is_purchase_history) {
            const details = [
              {
                title: 'Retailer',
                description: product.payload?.retailer || ''
              },
              {
                title: 'Product Type',
                description: strToCapitalize(getKey(product.payload?.generic_product_type, memberhubConstants.GENERIC_PRODUCT_TYPE)?.toLowerCase()) || ''
              },
              {
                title: 'Plan',
                description: product.payload?.plan || ''
              },
              {
                title: 'Application Date',
                description: product.payload?.application_date ? dayjs(product.payload?.application_date, 'DD/MM/YYYY').format('DD MMM YYYY') : ''
              }
            ]
            if (product.payload?.generic_product_type === memberhubConstants.GENERIC_PRODUCT_TYPE_FLAG.BROADBAND) {
              details.push(...[
                {
                  title: 'Address',
                  description: product.payload?.address || ''
                }
              ])
            }
            details.push(...[
              {
                title: 'Telco Reference #',
                description: product.payload?.telco_ref_number || ''
              },
              {
                title: 'Compare Club Reference #',
                description: product.payload?.live_submit_id || ''
              }
            ])
            return details
          }
          return [
            {
              title: 'Provider',
              description: product.payload?.provider || ''
            },
            {
              title: 'Reference #',
              description: product.payload?.reference || ''
            },
            {
              title: 'Next payment',
              description: product.payload?.actual_next_payment_date || ''
            },
            {
              title: 'Renewal date',
              description: product.payload?.renewal_date || ''
            },
            {
              title: 'Comments',
              description: product.payload?.comments || ''
            }
          ]
        default:
          return [
            {
              title: 'Policy name',
              description: `${product.payload?.policy_details?.derived_name || product.payload?.policy_details?.name}${(extraCoverExists ? ` with ${product.payload?.extra_details?.derived_name || product.payload?.extra_details?.name}` : '')}`
            },
            {
              title: 'Reference #',
              description: product.payload?.reference || ''
            },
            {
              title: 'Next payment',
              description: product.payload?.actual_next_payment_date || ''
            },
            {
              title: 'Coverage',
              description: product.payload?.coverage === 'Single Parent' ? 'Single Parent Family' : (product.payload?.coverage || '')
            },
            {
              title: 'Comments',
              description: product.payload?.comments || ''
            }
          ]
      }
    }
    return state.products?.map(product => {
      return {
        id: product.id,
        active: product.active,
        vertical: product.vertical,
        is_purchase_history: product.is_purchase_history,
        purchase_date: product?.payload?.purchase_date,
        name: product.payload?.nickname || productNamePerVertical(product),
        premium_amount: premiumAmountPerVertical(product),
        payment_frequency: paymentFrequencyPerVertical(product),
        productDetails: [...productDetailsPerVertical(product).filter(p => memberhubConstants.INLINE_EDIT_FIELDS.filter(f => product.vertical === memberhubConstants.VERTICAL_ID.GENERIC?f.label !== 'Next payment':true).find(f => f.label === p.title)||!!p.description || !!p.grouped || !!p.policyCoverageTooltip), ...[{
          title: 'Policy Document',
          files: product.files || []
        }]],
        files: product.files || [],
        category: product?.payload?.category === 'Other' ? product?.payload?.other_category : product?.payload?.category,
        income_tier_rebate: product?.payload?.income_tier_rebate,
        data: product?.payload,
        live_submit_id: product?.payload?.live_submit_id,
        status: licStatus(product?.payload?.status)
      }
    }) || []
  },
  getProduct (state) {
    return state.product
  },
  getLifeProduct (state) {
    return state.lifeProduct
  },
  getVaultArchivedProductsFormatted (state) {
    return state.archivedProducts?.map(product => {
      return {
        id: product.id,
        vertical: product.vertical,
        is_purchase_history: product.is_purchase_history,
        purchase_date: product?.payload?.purchase_date,
        name: product.payload?.nickname || productNamePerVertical(product),
        premium_amount: premiumAmountPerVertical(product),
        payment_frequency: paymentFrequencyPerVertical(product),
        category: product?.payload?.category === 'Other' ? product?.payload?.other_category : product?.payload?.category,
        data: product?.payload,
        archived_on: dayjs(product?.extras?.archived_at).format('DD/MM/YYYY')
      }
    }) || []
  }
}
export default getters
