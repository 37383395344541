/**
 * List of all components that is used in
 * <component/> tag or dynamic components.
 *
 * The reason for this dictionary is because,
 * `is` in <component /> only accepts static variables
 * example <component is="test" />.
 * It doesn't allow variables with dynamic functionality for some reason.
 *
 * This is to prevent using resolveComponent which
 * requires the to make all components global.
 */

const atomsMap = {
  AtomsCta: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/atoms/Cta/index.vue')
    })
  },

  AtomsButton: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/atoms/Button/index.vue')
    })
  },

  AtomsButtonBack: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/atoms/Button/Back/index.vue')
    })
  },

  AtomsImage: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/atoms/Image/index.vue')
    })
  },

  AtomsLink: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/atoms/Link/index.vue')
    })
  },

  AtomsInput: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/atoms/Input/index.vue')
    })
  },

  AtomsAccountSetupLoader: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/atoms/AccountSetupLoader/index.vue')
    })
  },

  AtomsWidgetWorks: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/atoms/WidgetWorks/index.vue')
    })
  },

  AtomsHeadline: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/atoms/Headline/index.vue')
    })
  },

  AtomsDatepicker: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/atoms/Datepicker/index.vue')
    })
  },

  AtomsAuthorBlock: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/atoms/AuthorBlock/index.vue')
    })
  }
}

const moleculesMap = {
  MoleculesSelectDropdown: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/molecules/SelectDropdown/index.vue')
    })
  },

  MoleculesSelection: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/molecules/Selection/index.vue')
    })
  },

  MoleculesStates: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/molecules/States/index.vue')
    })
  },

  MoleculesOccupation: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/molecules/Occupation/index.vue')
    })
  },

  MoleculesKeyPoints: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/molecules/KeyPoints/index.vue')
    })
  },

  MoleculesInlineAge: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/molecules/Inline/Age/index.vue')
    })
  },

  MoleculesInlineCardCta: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/molecules/Inline/CardCta/index.vue')
    })
  },

  MoleculesInlineLifeStage: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/molecules/Inline/LifeStage/index.vue')
    })
  },

  MoleculesInlinePartner: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/molecules/Inline/Partner/index.vue')
    })
  },

  MoleculesVerticalFormWidget: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/molecules/VerticalFormWidget/Sidebar/index.vue')
    })
  },

  MoleculesCalculatorWithDisclaimer: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/molecules/Calculator/WithDisclaimer/index.vue')
    })
  },

  MoleculesPostcode: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/molecules/Postcode/index.vue')
    })
  },

  MoleculesBirthdate: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/molecules/Birthdate/index.vue')
    })
  },

  MoleculesCountry: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/molecules/Country/index.vue')
    })
  }
}

const organismMap = {
  OrganismsQuickQuoteHealthInsurance: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/QuickQuote/HealthInsurance/index.vue')
    })
  },

  OrganismsQuickQuoteLifeInsurance: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/QuickQuote/LifeInsurance/index.vue')
    })
  },

  OrganismsQuickQuoteEnergy: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/QuickQuote/Energy/index.vue')
    })
  },

  OrganismsInlineLifeStageAbTest: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Inline/LifeStageAbTest/index.vue')
    })
  },

  OrganismsInlineAgeAbTest: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Inline/AgeAbTest/index.vue')
    })
  },
  OrganismsDashboardVaultProviderForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/ProviderForm/index.vue')
    })
  },
  OrganismsDashboardVaultStateForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/StateForm/index.vue')
    })
  },
  OrganismsDashboardVaultCoverageForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/CoverageForm/index.vue')
    })
  },
  OrganismsDashboardVaultPolicyNameForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/PolicyNameForm/index.vue')
    })
  },
  OrganismsDashboardVaultPremiumDetailsForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/PremiumDetailsForm/index.vue')
    })
  },
  OrganismsDashboardVaultPolicyDetailsConfirmation: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/PolicyDetailsConfirmation/index.vue')
    })
  },
  OrganismsDashboardVaultNextPaymentDateForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/NextPaymentDateForm/index.vue')
    })
  },
  OrganismsDashboardVaultReferenceForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/ReferenceForm/index.vue')
    })
  },
  OrganismsDashboardVaultNicknameForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/NicknameForm/index.vue')
    })
  },
  OrganismsDashboardVaultCommentsForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/CommentsForm/index.vue')
    })
  },
  OrganismsDashboardVaultPolicyCoversForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/PolicyCoversForm/index.vue')
    })
  },
  OrganismsDashboardVaultPaymentMethodForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/PaymentMethodForm/index.vue')
    })
  },
  OrganismsDashboardVaultCategoryForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/CategoryForm/index.vue')
    })
  },
  OrganismsDashboardVaultRenewalDateForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/RenewalDateForm/index.vue')
    })
  },
  OrganismsDashboardVaultProductTypeForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/ProductTypeForm/index.vue')
    })
  },
  OrganismsDashboardVaultProductUsageForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/ProductUsageForm/index.vue')
    })
  },
  OrganismsDashboardVaultMonthlyBillForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/MonthlyBillForm/index.vue')
    })
  },
  OrganismsDashboardVaultAddressForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/AddressForm/index.vue')
    })
  },
  OrganismsDashboardVaultFixedRateDetailsForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/FixedRateDetailsForm/index.vue')
    })
  },
  OrganismsDashboardVaultVariableRateDetailsForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/VariableRateForm/index.vue')
    })
  },
  OrganismsDashboardVaultAmountForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/AmountForm/index.vue')
    })
  },
  OrganismsDashboardVaultAmountSplitupForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/AmountSplitupForm/index.vue')
    })
  },
  OrganismsDashboardVaultStartDateForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/StartDateForm/index.vue')
    })
  },
  OrganismsDashboardVaultTermForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/TermForm/index.vue')
    })
  },
  OrganismsDashboardVaultPaymentTypeForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/PaymentTypeForm/index.vue')
    })
  },
  OrganismsDashboardVaultPropertyTypeForm: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/organisms/Dashboard/Vault/PropertyTypeForm/index.vue')
    })
  }
}

const iconsMap = {
  IconsVerticalsHomeHealth: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/verticals/home/Health.vue')
    })
  },

  IconsVerticalsEnergy: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/verticals/Energy.vue')
    })
  },

  IconsVerticalsHomeEnergy: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/verticals/home/Energy.vue')
    })
  },

  IconsVerticalsLife: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/verticals/Life.vue')
    })
  },

  IconsVerticalsHomeLife: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/verticals/home/Life.vue')
    })
  },

  IconsVerticalsHomeLoan: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/verticals/HomeLoan.vue')
    })
  },

  IconsVerticalsHomeHouseLoans: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/verticals/home/HouseLoans.vue')
    })
  },

  IconsVerticalsCreditCard: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/verticals/CreditCard.vue')
    })
  },

  IconsVerticalsHomeCreditCard: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/verticals/home/CreditCard.vue')
    })
  },

  IconsVerticalsCarInsurance: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/verticals/CarInsurance.vue')
    })
  },

  IconsVerticalsHomeCarInsurance: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/verticals/home/CarInsurance.vue')
    })
  },

  IconsVerticalsCarLoan: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/verticals/CarLoan.vue')
    })
  },

  IconsVerticalsHomeCarLoan: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/verticals/home/CarLoan.vue')
    })
  },

  IconsVerticalsPersonalLoan: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/verticals/PersonalLoan.vue')
    })
  },

  IconsVerticalsHomePersonalLoan: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/verticals/home/PersonalLoan.vue')
    })
  },

  IconsError: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/Error.vue')
    })
  },

  IconsClose: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/Close.vue')
    })
  },

  IconsRestrict: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/Restrict.vue')
    })
  },

  IconsGoldenStar: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/GoldenStar.vue')
    })
  },

  IconsTrash: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/Trash.vue')
    })
  },

  IconsFilter: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/Filter.vue')
    })
  },

  IconsVerticalsOVHC: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/verticals/OVHC.vue')
    })
  },

  IconsToolTip: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/ToolTip.vue')
    })
  },

  IconsCheckUnderline: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/icons/CheckUnderline.vue')
    })
  }
}

const templatesMap = {
  TemplatesContentfulContent: () => {
    return defineAsyncComponent({
      loader: () => /* @vite-ignore */ import('~/components/templates/Contentful/Content/index.vue')
    })
  }
}

export const getComponent = component => {
  if (!component) {
    // eslint-disable-next-line no-console
    console.log(
      `%c${component}%c is undefined/null!`,
      'color: cyan; font-weight: bold;',
      'color: cyan;  font-weight: normal;'
    )
    return
  }

  try {
    const temp = getKey(component, {
      default: null,
      ...atomsMap,
      ...moleculesMap,
      ...organismMap,
      ...templatesMap,
      ...iconsMap
    })
    return temp?.()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)

    const temp = typeof component === 'object'
      ? JSON.stringify(component)
      : component

    // eslint-disable-next-line no-console
    console.log(
      `%c${temp}%c is not defined in dictionary!`,
      'color: cyan; font-weight: bold;',
      'color: cyan;  font-weight: normal;'
    )
  }
}

export const renderComponent = (key, map) => getKey(key, map)

