declare global {
  // eslint-disable-next-line no-unused-vars
  interface window {
      Appcues: any
      AppcuesSettings: any
  }
}

export default defineNuxtPlugin({
  name: 'appcues',
  parallel: true,
  setup (nuxtApp) {
    const { appcuesId } = useEnv()

    if (!appcuesId) {
      // eslint-disable-next-line no-console
      console.warn('Appcues: Appcues ID not found.')
      return
    }

    const userStore = useUserStore()
    const userProfile = computed(() => userStore.getUserProfile || {})

    const identifyUser = async () => {
      const userIdentityObj = {
        name: userProfile.value?.given_name,
        email_hashed: userProfile.value?.hash_id,
        created_at: userProfile.value?.created_at,
        email_verified: userProfile.value?.email_verified,
        phone_verified: userProfile.value?.phone_verified,
        date_of_birth: userProfile.value?.profile?.dateOfBirth,
        postcode: userProfile.value?.profile?.postcode,
        state: userProfile.value?.profile?.state,
        product_interest: userProfile.value?.profile?.productInterest,
        profile_completion: userProfile.value?.profile?.profileCompletion
      }

      const userDetails = await userStore.getUserDetails()
      const user = { ...userIdentityObj, ...userDetails }

      // Trigger Appcues user identification
      if (window.Appcues) {
        window.Appcues.identify(user.email_hashed, user)
        // eslint-disable-next-line no-console
        console.info('Appcues: User identified:', user)
      } else {
        // eslint-disable-next-line no-console
        console.warn('Appcues: Appcues not loaded yet.')
      }
    }

    nuxtApp.provide('appcuesClient', {
      init: async () => {
        // Load Appcues script conditionally using useHead
        if (!window.Appcues && import.meta.client) {
          // eslint-disable-next-line no-console
          console.info('Appcues loading...')
          useHead({
            script: [
              {
                src: `//fast.appcues.com/${appcuesId}.js`,
                type: 'text/javascript',
                async: true,
                defer: true,
                onload: async () => {
                  window.AppcuesSettings = { enableURLDetection: true }
                  await identifyUser()
                  // eslint-disable-next-line no-console
                  console.info('Appcues loaded!')
                }
              }
            ]
          })
        } else {
          await identifyUser()
        }
      }
    })
  }
})
