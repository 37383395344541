export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"format-detection","content":"telephone=no"},{"name":"description","content":"Do your household bills need saving? Compare, change and save money on your health insurance, energy bills, home loans and life cover."}],"link":[{"rel":"icon","href":"/favicon.svg"},{"rel":"preconnect","href":"https://www.google-analytics.com"},{"rel":"dns-prefetch","href":"https://www.google-analytics.com"},{"rel":"preconnect","href":"https://www.googletagmanager.com"},{"rel":"dns-prefetch","href":"https://www.googletagmanager.com"},{"rel":"preconnect","href":"https://cdn.optimizely.com"},{"rel":"dns-prefetch","href":"https://cdn.optimizely.com"},{"rel":"preconnect","href":"https://cdn.amplitude.com"},{"rel":"dns-prefetch","href":"https://cdn.amplitude.com"},{"rel":"preconnect","href":"https://connect.facebook.net"},{"rel":"dns-prefetch","href":"https://connect.facebook.net"},{"rel":"preconnect","href":"https://cdn.taboola.com"},{"rel":"dns-prefetch","href":"https://cdn.taboola.com"}],"style":[],"script":[],"noscript":[],"title":"Compare Club: Health & Life Insurance, Energy, Loans & More","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000