import { default as _91slug_93bJs3rznmXeMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as indexqXZkSAWCYWMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as guidesqUdu7my6JAMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as _91response_93QkP6k4SUZ0Meta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as indexjiYuFmwpLvMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as schedule_45callbackTfntTHV1xXMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as about_45us5d1kPNKULrMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as callbackuQXpEMkn3kMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as mergerRCNkwsacPMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as comprehensiveycItuldDPeMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as index4FUGLffDZsMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91step_93F44q2QBjChMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as details9azOWlQbIfMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsfnRWqVVFY1Meta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexIzcuVb76QpMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexxq95kaqxw3Meta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as collection_45noticerVWZMULuHKMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as complaintsJDjptvwQJEMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45ustf7N9xhIFZMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as _91slug_93StwYVVFsRtMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as indexVYohVUZsEGMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as _91slug_93eKvVUozx4oMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as _91slug_93RCz73gCu0RMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as account_45settingsBcPiBvIMIxMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexxGIyJhfhfaMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as indexHdkxYt1U4fMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as indexpPNdtQQ1PDMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexWr67teUud9Meta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as indexvkS40q8agLMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as detailsEc8pdTtMFSMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/vault/[[product]]/details.vue?macro=true";
import { default as _91step_93ZRlPzfPMWSMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/[step].vue?macro=true";
import { default as additional_45details94Male5ALfMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/additional-details.vue?macro=true";
import { default as completionkNRLZTEafbMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/completion.vue?macro=true";
import { default as _91step_93yvgmlQhPfGMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/step/[step].vue?macro=true";
import { default as lic_45detailssXE6wJ1QWeMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/vault/[[product]]/lic-details.vue?macro=true";
import { default as rebaterSU4ilB2fEMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/vault/[[product]]/rebate.vue?macro=true";
import { default as index8VX25bVsSiMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91step_934FSPz6gz40Meta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsZyC0IUnth9Meta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as thank_45youfMVbXmXDN8Meta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as _91slug_93Gij4ZHrNbiMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as indexs0wTuZsFbmMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as _91author_93B8LzU3z5oTMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as indexiD2mSAQ6xZMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as forgot_45passwordMNVOSNt09CMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as _91step_93OQlhY0qaBDMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as expiredhAJfJJp7HuMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexU0hooOSYXxMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as indexXlKt6bg4KoMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as errorRhzMrHvhNBMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexGOljfdUq0CMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as live_45chatGvDIUPUjPsMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as addma5QeZHCYpMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as signupB9nqDYWcOCMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as post_45sale5xMtBeUsQeMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as indexXgBAkYy1iaMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as _91step_93DRM69JKiPgMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as details3jDiXt0YdgMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as indexxUyU4UDOoMMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as thank_45youqisQWJWI8BMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as index0kXciSyROhMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as indexIRU7iWpXa0Meta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as indexZGtvRUkIlhMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as indexif7oyKj1XCMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as _91step_93OMowmYdOMZMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as _91step_93kGgiSSKKxWMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as confirmationZB9LI8Pkg1Meta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as detailsGm5xWOkEP3Meta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as indexFNpyK8Iq7MMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as quotes_45results5OeIH0WmPXMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as thank_45youwaL8khTTweMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as _91type_938g08FwQgJsMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as indexdJ3YyM0dTiMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as indexxs85BPy0T9Meta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as interest_45ratesQx2rSo7nYaMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91step_93uw8Vz8UgQWMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as detailsKPw2D1o9nmMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as thank_45youQAQClOOVgoMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as indexAUnhaaDKYAMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/index.vue?macro=true";
import { default as confirmationKycKMKk6vPMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as detailsSlO1yS0PqOMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as indexPlOYkGlPJOMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as indexcIsND6WvaEMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as addLK4xYF24FLMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as thank_45youWGdRo63p56Meta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as indexybzFIq7fhOMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as _91step_931Fq3cmp7lpMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as details02D82Y3oSBMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as loginXgSrCdjguzMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/login.vue?macro=true";
import { default as _91slug_93IDPSmVZXPdMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as _91author_93TIzij5oQF8Meta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as indexJQGM9BfVOxMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as partnershipsOkc3srCkpxMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as indexVk8cfeuarSMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as post_45sale_45thank_45youmCNYVT0FLRMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as _91slug_938jV5U3AXA0Meta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as _91slug_935w60glYtR6Meta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as privacy_45policyKCloSJIYSzMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as register1mLZYyKdMPMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/register.vue?macro=true";
import { default as reset_45passwordgb2KZ5fORLMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as terms_45of_45useIISsZcy6QiMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as verifyrLRflt5uFlMeta } from "/codebuild/output/src1301617265/src/packages/nuxt/pages/verify.vue?macro=true";
export default [
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/[vertical]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/[vertical]/companies/index.vue").then(m => m.default || m)
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/[vertical]/guides.vue").then(m => m.default || m)
  },
  {
    name: schedule_45callbackTfntTHV1xXMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/[vertical]/schedule-callback.vue").then(m => m.default || m),
    children: [
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue").then(m => m.default || m)
  },
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/authorization/callback.vue").then(m => m.default || m)
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/authorization/merge.vue").then(m => m.default || m)
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/car-insurance/comprehensive.vue").then(m => m.default || m)
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/car-insurance/index.vue").then(m => m.default || m)
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue").then(m => m.default || m)
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/car-insurance/quote/form/details.vue").then(m => m.default || m)
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/car-insurance/quote/form/results.vue").then(m => m.default || m)
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/car-loans/index.vue").then(m => m.default || m)
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/collection-notice.vue").then(m => m.default || m)
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/complaints.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93StwYVVFsRtMeta || {},
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/credit-cards/[slug].vue").then(m => m.default || m)
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/credit-cards/index.vue").then(m => m.default || m)
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/credit-cards/preview/[slug].vue").then(m => m.default || m)
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsBcPiBvIMIxMeta || {},
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/account-settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexxGIyJhfhfaMeta || {},
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexHdkxYt1U4fMeta || {},
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexpPNdtQQ1PDMeta || {},
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/perks/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexWr67teUud9Meta || {},
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/quotes/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    meta: indexvkS40q8agLMeta || {},
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/rewards/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product?/details",
    meta: detailsEc8pdTtMFSMeta || {},
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/vault/[[product]]/details.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-vault-product-form-vertical-step",
    path: "/dashboard/vault/:product?/form/:vertical()/:step()",
    meta: _91step_93ZRlPzfPMWSMeta || {},
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/[step].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-vault-product-form-vertical-additional-details",
    path: "/dashboard/vault/:product?/form/:vertical()/additional-details",
    meta: additional_45details94Male5ALfMeta || {},
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/additional-details.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-vault-product-form-vertical-completion",
    path: "/dashboard/vault/:product?/form/:vertical()/completion",
    meta: completionkNRLZTEafbMeta || {},
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/completion.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-vault-product-form-vertical-step-step",
    path: "/dashboard/vault/:product?/form/:vertical()/step/:step()",
    meta: _91step_93yvgmlQhPfGMeta || {},
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/[vertical]/step/[step].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product?/lic-details",
    meta: lic_45detailssXE6wJ1QWeMeta || {},
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/vault/[[product]]/lic-details.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product?/rebate",
    meta: rebaterSU4ilB2fEMeta || {},
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/dashboard/vault/[[product]]/rebate.vue").then(m => m.default || m)
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/energy/index.vue").then(m => m.default || m)
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/energy/quote/form/[step].vue").then(m => m.default || m)
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/energy/quote/form/details.vue").then(m => m.default || m)
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/energy/quote/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/expert-analysis/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/expert-analysis/author/[author].vue").then(m => m.default || m)
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/expert-analysis/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordMNVOSNt09CMeta || {},
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/application/[step].vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/application/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/error.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/index.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/live-chat.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/partners/add.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/partners/signup.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/post-sale.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/quote/form/details.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue").then(m => m.default || m)
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/home-loans/calculators/[type].vue").then(m => m.default || m)
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/home-loans/calculators/index.vue").then(m => m.default || m)
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/home-loans/index.vue").then(m => m.default || m)
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/home-loans/interest-rates.vue").then(m => m.default || m)
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/home-loans/quote/form/[step].vue").then(m => m.default || m)
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/home-loans/quote/form/details.vue").then(m => m.default || m)
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/home-loans/quote/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue").then(m => m.default || m)
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue").then(m => m.default || m)
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/life-insurance/index.vue").then(m => m.default || m)
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/life-insurance/partners/add.vue").then(m => m.default || m)
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue").then(m => m.default || m)
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue").then(m => m.default || m)
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/life-insurance/quote/form/details.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginXgSrCdjguzMeta || {},
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/media/[slug].vue").then(m => m.default || m)
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/media/author/[author].vue").then(m => m.default || m)
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/media/index.vue").then(m => m.default || m)
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/partnerships.vue").then(m => m.default || m)
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/personal-loans/index.vue").then(m => m.default || m)
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/post-sale-thank-you.vue").then(m => m.default || m)
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/preview/[vertical]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: register1mLZYyKdMPMeta || {},
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordgb2KZ5fORLMeta || {},
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/[vertical]/guides.vue").then(m => m.default || m)
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src1301617265/src/packages/nuxt/pages/[vertical]/companies/index.vue").then(m => m.default || m)
  }
]