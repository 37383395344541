import { getKey } from '~/utils/helpers'

export const CONFIG = {
  baseUrl: (env, type = 'default') => {
    return getKey(type, {
      default: getKey(env, {
        develop: 'https://dev.api.compareclub.com.au',
        staging: 'https://staging.api.compareclub.com.au',
        production: 'https://api.compareclub.com.au'
      }),

      alternativemedia: getKey(env, {
        develop: 'https://dev.api.alternativemedia.com.au',
        staging: 'https://staging.api.alternativemedia.com.au',
        production: 'https://api.alternativemedia.com.au'
      }),

      memberhub: getKey(env, {
        develop: 'https://dev.api.compareclub.com.au/memberhub',
        staging: 'https://staging.api.compareclub.com.au/memberhub',
        production: 'https://api.compareclub.com.au/memberhub'
      })
    })
  },
  // Retrieve the API Key based on the associated vertical from the env
  getApiKey: (vertical = 'HealthInsurance') => {
    const env = useRuntimeConfig()

    const APIKEY = {
      Default: env.apikeyHealthInsurance,
      HealthInsurance: env.apikeyHealthInsurance,
      LifeInsurance: env.apikeyLifeInsurance,
      IncomeProtection: env.apikeyIncomeProtection,
      Energy: env.apikeyEnergy,
      OVHC: env.apikeyOvhcInsurance,
      CarInsurance: env.apikeyCarInsurance,
      HomeLoans: env.apikeyHomeLoans,
      HomeLoanForm: env.apikeyHomeLoans,
      Email: env.apikeyEmail,
      ReCaptcha: env.apikeyRecaptcha,
      PartnerNewClient: env.apikeyPartnerNewClient
    }

    return APIKEY[vertical]
  }
}
