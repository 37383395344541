import _isEmpty from 'underscore/cjs/isEmpty.js'
import memberhubConstants from '~/constants/memberhub.constants'
/*
 * API response analyser
 * @param response
 * @param showToast
 * @param schema
 * @returns {Promise<never>|Promise<unknown>}
 */

export function checkResponse (
  res,
  showToast = true,
  schema = {}
) {
  const { $toast, $bus } = useNuxtApp()
  // const userStore = useUserStore()
  const response = {
    code: res?.data?.value?.status || res?.data?.value?.code || res?.error?.value?.data?.code || res?.data?.value?.data?.status,
    data: res?.data?.value || res?.error?.value?.data || res?.data?.value?.data
  }

  if (_isEmpty(response)) {
    $toast.error('Something went wrong')

    return Promise.reject(response || {})
  }
  const code = response.code || response.status
  switch (parseInt(code)) {
    case memberhubConstants.API_SUCCESS:
    case memberhubConstants.API_CREATED:
      if (showToast) {
        $toast.success(response?.data?.message || response?.data?.data?.message || 'Successfully updated')
      }

      return Promise.resolve(response?.data || response)

    case memberhubConstants.API_VALIDATION_ERROR:
      if (response?.data?.errors) {
        Object.entries(response.data.errors).forEach(entry => {
          if (schema && !schema[entry[0]]) {
            if(showToast) $toast.error(entry[1][0])
          } else {
            $bus.emit(`invalid-form-key-${entry[0]}`, { message: entry[1][0] })
          }
        })

        return Promise.reject(response)
      }

      return Promise.reject(response)
    default:
      if (showToast) {
        $toast.error(response.data && response.data.message ? response.data.message : (response?.data?.data?.message || 'The Request Failed! Please try again.'))
      }

      return Promise.reject(response)
  }
}
